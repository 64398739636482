import React, { Component } from 'react';

export default class Copyright extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
    }

    render() {
        return (
        <div className="copyright">
            © 2020 Copyright: <span className="fw-600 text-white">Volkeno SARL</span>
        </div>
        );
    }
}