import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Copyright from '../Copyright/Copyright';

export default class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
    }

    render() {
        return (
            <div className="message-all-page">
                <div className="container">
                    <div className="row dis-flex itm-center">
                        <div className="col-xl-1 col-lg-1 col-md-2 col-sm-2 dis-flex justify-content-center">
                            <img className="logo-message-all-page" src={`https://via.placeholder.com/150x150`} alt="Footer" />
                        </div>
                        <div className="col-xl-11 col-lg-11 col-md-10 col-sm-10">
                            <p className="text-message-all-page mb-4">La mise en œuvre de cette plateforme d’apprentissage est soutenue par le programme “Réussir au Sénégal” de la Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) GmbH, 
                            financé par le Ministère fédéral allemand de la Coopération économique et du Développement (BMZ) et l’Etat libre de Bavière.
                            </p>
                            <div className="row">
                                <div className="col-lg-2 col-md-3 col-sm-3">
                                    <NavLink className="link-message-all-page" to="/">Mentions légales</NavLink>
                                </div>
                                <div className="col-md-4 col-sm-4 mb-3">
                                    <NavLink className="link-message-all-page" to="/">Politique de confidentialité </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="dis-flex justify-content-center">
                    <Copyright />
                </div>
            </div>
            
        );
    }
}